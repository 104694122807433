import Api from "@/includes/logic/Api";
import { EditPublishedPost } from "@/views/posts-planner/posts.types";

import { AxiosPromise } from "axios";

export default class PostsApi {
  static async editPublishedPost(type: 'tg', body: EditPublishedPost): AxiosPromise<void> {
    return await Api.sendRequest(type, 'editpublishedpost', body)
  }
}
