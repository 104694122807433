import SendCommentActionBuilderCustom from "./PostActionTypes/SendCommentAction/SendCommentActionBuilderCustom";
import EditPostActionBuilderCustom from "./PostActionTypes/EditPostAction/EditPostActionBuilderCustom";

import { PostActionTypeEnum } from "piramis-base-components/src/shared/modules/posting/types";
import DeletePostActionBuilder
  from 'piramis-base-components/src/shared/modules/posting/PostActions/ActionTypes/DeletePostAction/DeletePostActionBuilder'
import UnpinChatMessageActionBuilder
  from 'piramis-base-components/src/shared/modules/posting/PostActions/ActionTypes/UnpinChatMessageAction/UnpinChatMessageActionBuilder'

import { Component } from "vue-property-decorator";
import Vue from "vue";

@Component({})
export default class NewActionsList extends Vue {
    get defaultActions() {
        return {
            [PostActionTypeEnum.UnpinChatMessageAction]: new UnpinChatMessageActionBuilder(),
            [PostActionTypeEnum.SendCommentAction]: new SendCommentActionBuilderCustom(),
            [PostActionTypeEnum.EditPostAction]: new EditPostActionBuilderCustom(),
            [PostActionTypeEnum.DeletePostAction]: new DeletePostActionBuilder(),
        }
    }
}
