





























import PeriodConditions from "@/views/posts-planner/components/Post/Period/PeriodConditions.vue";
import { InputSetups } from "@/mixins/input-setups";
import { Conditions, CreatedPost } from "@/views/posts-planner/posts.types";

import { FieldData } from "piramis-base-components/src/components/Pi/types";
import { UseFields } from "piramis-base-components/src/components/Pi/index";

import Component from "vue-class-component";
import { Mixins, Prop, VModel, Watch } from "vue-property-decorator";

@Component({
  components: {
    PeriodConditions
  }
})
export default class PeriodSettings extends Mixins(InputSetups, UseFields) {

  @VModel() model !: CreatedPost

  @Prop() initConditions !: Array<Conditions>

  @Watch('scheduleCondition')
  onScheduleConditionsChange() {
    if (this.model.schedule && this.model.schedule.period) {
      this.model.schedule.period.conditions = this.scheduleCondition
    }
  }

  scheduleCondition: Array<Conditions> = []

  modelSetter(args: FieldData): FieldData {
    args.setter = (value: any): void => {
      this.$set(args.model, args.key, value)
    }

    return args
  }

  created() {
    if (this.model.schedule?.period === null) {
      this.model.schedule.period = {
        interval: 0,
        unit: 'MINUTES',
        conditions: []
      }
    }
  }
}
