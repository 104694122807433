var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"period-settings"},[_c('time-unit-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model.schedule.period,
        'key': 'interval',
        'step': _vm.StepUnit.Minute,
        'validation': 'required|min_value:1',
        'prefix': 'post_',
        'units': [ _vm.StepUnit.Minute, _vm.StepUnit.Day, _vm.StepUnit.Hour, _vm.StepUnit.Week, _vm.StepUnit.Month ],
        'settingWrapper': {
          customiseValue: _vm.StepUnit.Day / 60,
          hasFieldButton: true,
          disabledValue: 0
        }
      },
    }}}),_c('period-conditions',{attrs:{"period":_vm.model.schedule.period,"initConditions":_vm.initConditions},model:{value:(_vm.scheduleCondition),callback:function ($$v) {_vm.scheduleCondition=$$v},expression:"scheduleCondition"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }